import English from "../../lang/en.json";
import French from "../../lang/fr.json";
import German from "../../lang/de.json";
import Dutch from "../../lang/nl.json";

export function determineMessageSource(locale: string): Record<string, string> {
    if (locale.toLowerCase().includes("fr")) {
        return French;
    }

    if (locale.toLowerCase().includes("nl")) {
        return Dutch;
    }

    if (locale.toLowerCase().includes("de")) {
        return German;
    }

    return English;
}

export function getCurrentLanguage(): string {
    const match = document.cookie.match('renta-language\\s*=\\s*([^;]+)');

    if (match && match.length >= 2) {
        return match[1]; // language code resides on index 1
    }

    return "en";
}
