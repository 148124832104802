import React, {useEffect} from "react";
import {Calendar} from "primereact/calendar";
import {calendarValueToJsDate, todayAtMidnight, tomorrowAtMidnight} from "../common/utilities";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {SelectItem} from "primereact/selectitem";
import {Button} from "primereact/button";
import {getApplications, getHttpMethods, getHttpStatusCodes} from "./ApiServiceRequestService";
import {FormattedMessage, useIntl} from "react-intl";
import {Nullable} from "primereact/ts-helpers";
import {SplitButton} from "primereact/splitbutton";
import {MenuItem} from "primereact/menuitem";

export interface Filters {
    httpMethod?: string
    application?: string
    httpStatusCode?: string
    startDatetimeFrom?: Nullable<string | Date | Date[]>
    startDatetimeUntil?: Nullable<string | Date | Date[]>
    incomingUrl?: string
    outgoingUrl?: string
    requestBody?: string
    requestHeaders?: string
    responseBody?: string
    responseHeaders?: string
    userEmail?: string
}

export interface ApiServiceSearchFiltersProps {
    filters: Filters
    searchInProgress: boolean
    historyOptions: string[]

    onFiltersUpdated(filters: Filters): void

    onSearchInitiated(): void
    onAbortSearch():void
    onHistorySelected(selected: string): void
}

const ApiServiceRequestSearchFilters = ({
                                            searchInProgress,
                                            onFiltersUpdated,
                                            onSearchInitiated,
                                            onAbortSearch,
                                            filters,
                                            historyOptions,
                                            onHistorySelected,
                                        }: ApiServiceSearchFiltersProps) => {

    const labelWidth = "200px"

    const [applicationOptions, setApplicationOptions] = React.useState<SelectItem[]>([])
    const [httpMethodOptions, setHttpMethodOptions] = React.useState<SelectItem[]>([])
    const [httpStatusOptions, setHttpStatusOptions] = React.useState<SelectItem[]>([])

    const [applicationOptionsLoading, setApplicationOptionsLoading] = React.useState<boolean>()
    const [httpMethodOptionsLoading, setHttpMethodOptionsLoading] = React.useState<boolean>()
    const [httpStatusOptionsLoading, setHttpStatusOptionsLoading] = React.useState<boolean>()

    const loadHttpMethods = () => void getHttpMethods().then(response => {
        setHttpMethodOptionsLoading(true)
        setHttpMethodOptions(response.data.map<SelectItem>(value => {
            setHttpMethodOptionsLoading(false)
            return {label: value, value: value}
        }))
    })

    const loadHttpStatusCodes = () => void getHttpStatusCodes().then(response => {
        setHttpStatusOptionsLoading(true)
        void setHttpStatusOptions(response.data.map<SelectItem>(value => {
            setHttpStatusOptionsLoading(false)
            return {label: value, value: value}
        }));
    })

    const loadApplications = () => void getApplications().then(response => {
        setApplicationOptionsLoading(true)
        void setApplicationOptions(response.data.map<SelectItem>(value => {
            setApplicationOptionsLoading(false)
            return {label: value, value: value}
        }));
    });

    useEffect(() => {
        loadHttpMethods();
        loadHttpStatusCodes();
        loadApplications();
        return () => {
        };
    }, []);

    function resetFilters() {
        const searchFilters: Filters = {
            httpMethod: "",
            application: "",
            httpStatusCode: "",
            startDatetimeFrom: "",
            startDatetimeUntil: "",
            incomingUrl: "",
            outgoingUrl: "",
            requestBody: "",
            requestHeaders: "",
            responseBody: "",
            responseHeaders: "",
            userEmail: ""
        };
        onFiltersUpdated(searchFilters)
    }

    function mapOptions(history: string[]) : MenuItem[]{
        if(searchInProgress) return [];
        return history.map(h => {
            const item : MenuItem = {};
            item.label = decodeURIComponent(h).replaceAll("&", " ")
            item.command = () =>   onHistorySelected(h)
            return item;
        });
    }

    return (
        <form onSubmit={e => {
            e.preventDefault();
            onSearchInitiated()
        }}>
            <div className="grid">
                <div className="col-12 md:col-6">
                    <div className="field grid">
                        <label htmlFor="startDateTime" className="col-fixed" style={{width: labelWidth}}>
                            <FormattedMessage id="requests.filter.startedFrom"/>
                        </label>
                        <div className="col">
                            <Calendar id="startDateTime"
                                      showTime
                                      showSeconds
                                      keepInvalid={true}
                                      value={filters.startDatetimeFrom}
                                      onChange={e => onFiltersUpdated({
                                          ...filters,
                                          startDatetimeFrom: e.value as Date
                                      })}
                                      className="w-full"
                                      dateFormat="dd/mm/yy"
                                      viewDate={calendarValueToJsDate(filters.startDatetimeFrom) || todayAtMidnight()} />
                        </div>
                    </div>
                    <div className="field grid">
                        <label htmlFor="application" className="col-fixed"
                               style={{width: labelWidth}}>
                            <FormattedMessage id="requests.filter.application"/>
                        </label>
                        <div className="col">
                            <Dropdown inputId="application"
                                      showClear
                                      options={applicationOptions}
                                      value={filters.application}
                                      onChange={e => onFiltersUpdated({
                                          ...filters,
                                          application: e.value
                                      })}
                                      disabled={applicationOptionsLoading}
                                      className="w-full"/>
                        </div>
                    </div>

                    <div className="field grid">
                        <label htmlFor="requestBody" className="col-fixed" style={{width: labelWidth}}>
                            <FormattedMessage id="requests.filter.requestBody"/>
                        </label>
                        <div className="col">
                            <InputText id="requestBody"
                                       value={filters.requestBody}
                                       onChange={e => onFiltersUpdated({
                                           ...filters,
                                           requestBody: e.target.value
                                       })}
                                       className="w-full"/>
                        </div>
                    </div>

                    <div className="field grid">
                        <label htmlFor="requestHeader" className="col-fixed" style={{width: labelWidth}}>
                            <FormattedMessage id="requests.filter.requestHeaders"/>
                        </label>
                        <div className="col">
                            <InputText id="requestHeader"
                                       value={filters.requestHeaders}
                                       onChange={e => onFiltersUpdated({
                                           ...filters,
                                           requestHeaders: e.target.value
                                       })}
                                       className="w-full"/>
                        </div>
                    </div>

                    <div className="field grid">
                        <label htmlFor="incomingUrl" className="col-fixed" style={{width: labelWidth}}>
                            <FormattedMessage id="requests.filter.incomingUrl"/>
                        </label>
                        <div className="col">
                            <InputText id="incomingUrl"
                                       value={filters.incomingUrl}
                                       onChange={e => onFiltersUpdated({
                                           ...filters,
                                           incomingUrl: e.target.value
                                       })}
                                       className="w-full"/>
                        </div>
                    </div>

                    <div className="field grid">
                        <label htmlFor="httpMethod" className="col-fixed" style={{width: labelWidth}}>
                            <FormattedMessage id="requests.filter.httpMethod"/>
                        </label>
                        <div className="col">
                            <Dropdown showClear
                                      id="httpMethod"
                                      options={httpMethodOptions}
                                      value={filters.httpMethod}
                                      onChange={e => onFiltersUpdated({
                                          ...filters,
                                          httpMethod: e.value
                                      })}
                                      disabled={httpMethodOptionsLoading}
                                      className="w-full"/>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6">

                    <div className="field grid">
                        <label htmlFor="endDateTime" className="col-fixed" style={{width: labelWidth}}>
                            <FormattedMessage id="requests.filter.startedUntil"/>
                        </label>
                        <div className="col">
                            <Calendar id="endDateTime"
                                      showTime
                                      showSeconds
                                      keepInvalid={true}
                                      value={filters.startDatetimeUntil}
                                      onChange={e => onFiltersUpdated({
                                          ...filters,
                                          startDatetimeUntil: e.value as Date
                                      })}
                                      className="w-full"
                                      dateFormat="dd/mm/yy"
                                      viewDate={calendarValueToJsDate(filters.startDatetimeUntil) ||  tomorrowAtMidnight()}/>
                        </div>
                    </div>

                    <div className="field grid">
                        <label htmlFor="userEmail" className="col-fixed" style={{width: labelWidth}}>
                            <FormattedMessage id="requests.filter.userEmail"/>
                        </label>

                        <div className="col">
                            <InputText id="userEmail"
                                       value={filters.userEmail}
                                       onChange={e => onFiltersUpdated({
                                           ...filters,
                                           userEmail: e.target.value
                                       })}
                                       className="w-full"/>
                        </div>
                    </div>

                    <div className="field grid">
                        <label htmlFor="responseBody" className="col-fixed" style={{width: labelWidth}}>
                            <FormattedMessage id="requests.filter.responseBody"/>
                        </label>
                        <div className="col">
                            <InputText id="responseBody"
                                       value={filters.responseBody}
                                       onChange={e => onFiltersUpdated({
                                           ...filters,
                                           responseBody: e.target.value
                                       })}
                                       className="w-full"/>
                        </div>
                    </div>

                    <div className="field grid">
                        <label htmlFor="responseHeader" className="col-fixed" style={{width: labelWidth}}>
                            <FormattedMessage id="requests.filter.responseHeaders"/>
                        </label>
                        <div className="col">
                            <InputText id="responseHeader"
                                       value={filters.responseHeaders}
                                       onChange={e => onFiltersUpdated({
                                           ...filters,
                                           responseHeaders: e.target.value
                                       })}
                                       className="w-full"/>
                        </div>
                    </div>

                    <div className="field grid">
                        <label htmlFor="outgoingUrl" className="col-fixed" style={{width: labelWidth}}>
                            <FormattedMessage id="requests.filter.outgoingUrl"/>
                        </label>
                        <div className="col">
                            <InputText id="outgoingUrl"
                                       value={filters.outgoingUrl}
                                       onChange={e => onFiltersUpdated({
                                           ...filters,
                                           outgoingUrl: e.target.value
                                       })}
                                       className="w-full"/>
                        </div>
                    </div>

                    <div className="field grid">
                        <label htmlFor="httpStatusCode" className="col-fixed" style={{width: labelWidth}}>
                            <FormattedMessage id="requests.filter.httpStatusCode"/>
                        </label>
                        <div className="col">
                            <Dropdown showClear
                                      id="httpStatusCode"
                                      options={httpStatusOptions}
                                      value={filters.httpStatusCode}
                                      onChange={e => onFiltersUpdated({
                                          ...filters,
                                          httpStatusCode: e.value
                                      })}
                                      disabled={httpStatusOptionsLoading}
                                      className="w-full"/>
                        </div>
                    </div>
                </div>
                <div className="col-12 flex flex-row justify-content-end">
                    <div className="mb-2">
                        <Button type="reset" className="mr-1 p-button-link"
                                label={useIntl().formatMessage({id: "common.button.reset"})} onClick={resetFilters}/>
                        <SplitButton buttonProps={{"type": "submit"}}  
                                     label={useIntl().formatMessage({id: "common.button.search"})}
                                     loading={searchInProgress} 
                                     model={mapOptions(historyOptions)}
                                     menuStyle={{width: "auto", maxWidth:"100%", wordBreak:"break-word"}} 
                                     disabled={searchInProgress}
                        />
                        {searchInProgress ? <Button icon="pi pi-times" severity="danger" aria-label="Cancel"
                                                    onClick={onAbortSearch}/> : ""}
                    </div>
                </div>
            </div>
        </form>
    );
}

export default ApiServiceRequestSearchFilters;
