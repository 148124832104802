import axios, {AxiosResponse} from "axios";

axios.defaults.withCredentials = true

export interface SftpUserDTO {
    id: string
    application: string
    userName: string
    email: string
    logonEnabled: boolean
    editable: string,
    directories?: string[],
    admin: boolean,
    sshKey?: string
}

export interface FmsConfigurationDTO {
    id: string
    rsNumber: number
    calculatePlateholder: boolean
    uploadType: string
    managedServicePickup: string
    retributionPickup: string
    companyName?: string
    sftpUserId?: string
    email?: string
    logonEnabled?: boolean
}

export interface MrtConfigurationDTO {
    id: string
    rsNumber: number
    overwriteCleanUpDays: number
    publicOverwrite: string
    extraCopiesTo: number
    companyName: string
    sftpUserId: string
    email: string
    logonEnabled: boolean
    matrices: boolean
    matricesRetourFile: boolean
}

export interface OrdConfigurationDTO {
    id: string
    rsNumber: number
    overwriteCleanUpDays: number
    companyName: string
    sftpUserId: string
    email: string
    logonEnabled: boolean
    supplierListProvided: string

}

export interface ReiConfigurationDTO {
    id: string
    rsNumber: number
    csvEmails: string
    xlsxEmails: string
    bmf: boolean
    csv: boolean
    pdf: boolean
    sig: boolean
    xlsx: boolean
    xml: boolean
    ordSftp: boolean
    rename: boolean
    publicOverwrite: string
    removePoints: boolean
    companyName: string
    sftpUserId: string
    email: string
    logonEnabled: boolean
}

export interface CreateSftpUserDTO {
    application: string
    userName: string
    email: string
    directories?: string[],
    admin: boolean,
    logonEnabled: boolean
    sshKey?: string
}

export interface UpdateSftpUserDTO {
    email: string
    directories?: string[],
    logonEnabled: boolean
    sshKey?: string
}

export interface ValidationError {
    fieldName: string,
    error: string,
    translations: Translations
}

export interface Translations {
    [key: string]: string | undefined
}

export function createSftpUser(request: CreateSftpUserDTO): Promise<any> {
    return axios.post<SftpUserDTO[]>(window.REACT_APP_API_ENDPOINT + "/file-service/sftp-user", request)
        .then(response => response.data);
}

export function updateSftpUser(id: string, request: UpdateSftpUserDTO): Promise<any> {
    return axios.put<SftpUserDTO[]>(window.REACT_APP_API_ENDPOINT + "/file-service/sftp-user/" + id, request)
        .then(response => response.data);
}

export function loadSftpUsers(): Promise<SftpUserDTO[]> {
    return axios.get<SftpUserDTO[]>(window.REACT_APP_API_ENDPOINT + "/file-service/sftp-user")
        .then(response => response.data);
}

export function loadFmsConfigurations(): Promise<FmsConfigurationDTO[]> {
    return axios.get<FmsConfigurationDTO[]>(window.REACT_APP_API_ENDPOINT + "/file-service/fms")
        .then(response => response.data);
}

export function loadMrtConfigurations(): Promise<MrtConfigurationDTO[]> {
    return axios.get<MrtConfigurationDTO[]>(window.REACT_APP_API_ENDPOINT + "/file-service/mrt")
        .then(response => response.data);
}

export function loadOrdConfigurations(): Promise<OrdConfigurationDTO[]> {
    return axios.get<OrdConfigurationDTO[]>(window.REACT_APP_API_ENDPOINT + "/file-service/ord")
        .then(response => response.data);
}

export function loadReiConfigurations(): Promise<ReiConfigurationDTO[]> {
    return axios.get<ReiConfigurationDTO[]>(window.REACT_APP_API_ENDPOINT + "/file-service/rei")
        .then(response => response.data);
}

export function resetPassword(sftpUserId: string): Promise<AxiosResponse<String, any>> {
    return axios.post<String>(window.REACT_APP_API_ENDPOINT + "/file-service/sftp-user/" + sftpUserId + "/reset-password");
}