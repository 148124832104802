import {loadReiConfigurations, ReiConfigurationDTO} from "./FileServiceService";
import React, {useEffect} from "react";
import PageContent from "../common/PageContent";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FileServicePageTitleBar} from "./FileServicePageTitleBar";
import {useIntl} from "react-intl";
import EnabledBadge from "../common/EnabledBadge";

const ReiConfigPage = () => {

    const intl = useIntl()

    const [reiConfigurations, setReiConfigurations] = React.useState<ReiConfigurationDTO[]>([]);
    const [loading, setLoading] = React.useState(false)

    useEffect(() => {
        setLoading(true);
        loadReiConfigurations().then(reiConfig => {
            setReiConfigurations(reiConfig);
            setLoading(false);
        })

    }, []);

    return (
        <>
            <FileServicePageTitleBar title={intl.formatMessage({id: "rei-config.title"})}/>
            <PageContent>
                <div className="border-1 border-black-alpha-20">
                    <DataTable value={reiConfigurations} stripedRows loading={loading} scrollable scrollHeight="calc(100vh - 328px)">
                        <Column field="rsNumber"
                                header={intl.formatMessage({id: "config.table.header.rs-number"})}
                                style={{minWidth: "120px"}}
                        />
                        <Column field="companyName"
                                header={intl.formatMessage({id: "config.table.header.company-name"})}
                                style={{minWidth: "100px"}}
                        />
                        <Column field="email"
                                header={intl.formatMessage({id: "config.table.header.email"})}
                                style={{minWidth: "250px"}}
                        />
                        <Column field="logonEnabled"
                                header={intl.formatMessage({id: "config.table.header.logon-enabled"})}
                                style={{textAlign: "center"}}
                                body={(dto: ReiConfigurationDTO) => {
                                    return <EnabledBadge value={dto.logonEnabled}/>
                                }}
                        />
                        <Column field="pdf" header=".pdf"
                                style={{textAlign: "center"}}
                                body={(dto: ReiConfigurationDTO) => {
                                    return <EnabledBadge value={dto.pdf}/>
                                }}
                        />
                        <Column field="xml" header=".xml"
                                style={{textAlign: "center"}}
                                body={(dto: ReiConfigurationDTO) => {
                                    return <EnabledBadge value={dto.xml}/>
                                }}
                        />
                        <Column field="bmf" header=".bmf"
                                style={{textAlign: "center"}}
                                body={(dto: ReiConfigurationDTO) => {
                                    return <EnabledBadge value={dto.bmf}/>
                                }}
                        />
                        <Column field="csv" header=".csv"
                                style={{textAlign: "center"}}
                                body={((dto: ReiConfigurationDTO) => {
                                    return <EnabledBadge value={dto.csv}/>
                                })}
                        />
                        <Column field="xlsx" header=".xlsx"
                                style={{textAlign: "center"}}
                                body={((dto: ReiConfigurationDTO) => {
                                    return <EnabledBadge value={dto.xlsx}/>
                                })}
                        />
                        <Column field="sig" header=".sig"
                                style={{textAlign: "center"}}
                                body={(dto: ReiConfigurationDTO) => {
                                    return <EnabledBadge value={dto.sig}/>
                                }}
                        />
                        <Column field="csvEmails"
                                header={intl.formatMessage({id: "rei-config.table.header.email-csv"})}/>
                        <Column field="xlsxEmails"
                                header={intl.formatMessage({id: "rei-config.table.header.email-xlsx"})}/>
                        <Column field="ordSftp" header={intl.formatMessage({id: "rei-config.table.header.ordsftp"})}
                                style={{minWidth: "110px", textAlign: "center"}}
                                body={(dto: ReiConfigurationDTO) => {
                                    return <EnabledBadge value={dto.ordSftp}/>
                                }}
                        />
                        <Column field="rename" header={intl.formatMessage({id: "rei-config.table.header.rename"})}
                                style={{textAlign: "center"}}
                                body={(dto: ReiConfigurationDTO) => {
                                    return <EnabledBadge value={dto.rename}/>
                                }}
                        />
                        <Column field="publicOverwrite"
                                header={intl.formatMessage({id: "config.table.header.public-overwrite"})}/>
                        <Column field="removePoints"
                                header={intl.formatMessage({id: "rei-config.table.header.remove.points"})}
                                style={{textAlign: "center"}}
                                body={(dto: ReiConfigurationDTO) => {
                                    return <EnabledBadge value={dto.removePoints}/>
                                }}
                        />
                    </DataTable>
                </div>
            </PageContent>
        </>
    )
}

export default ReiConfigPage;
