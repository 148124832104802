import React from "react";

interface EnabledBadgeProps {
    value?: boolean
}

const EnabledBadge = ({value}: EnabledBadgeProps) => {
    return value ?
        <span className="pi pi-check-circle text-green-600"/> :
        <span className="pi pi-times-circle text-red-600"/>
}

export default EnabledBadge
