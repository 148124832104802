import {Button} from "primereact/button";
import React, {useEffect, useRef, useState} from "react";
import PageTitleBar from "../common/PageTitleBar";
import PageContent from "../common/PageContent";
import ApiServiceRequestSearchFilters, {Filters} from "./ApiServiceRequestSearchFilters";
import {PagedDataTable, PagingSettings} from "../common/PagedDataTable";
import {ApiServiceRequest} from "../common/model";
import {Column} from "primereact/column";
import {DateTime} from "luxon";
import {abortLastSearchRequestsCall, searchRequests} from "./ApiServiceRequestService";
import ApiServiceRequestDetailDialog from "./ApiServiceRequestDetailDialog";
import {useIntl} from "react-intl";
import {Toast} from "primereact/toast";
import {PaginatorPageChangeEvent} from "primereact/paginator";
import {calendarValueToJsDate, jsDateToIsoStringWithoutTimezone, toBelgiumDateTime} from "../common/utilities";
import ApplicationBadge from "../common/ApplicationBadge";
import HttpStatusBadge from "../common/HttpStatusBadge";
import {useNavigate} from "react-router-dom";

export function ApiServiceRequestPage() {

    const toast = useRef<any>(null)
    const intl = useIntl()
    
    let initialFilters = getOptionalQueryParametersFromURL(window.location.search);
    
    const [initialLoad, setInitialLoad] = useState<boolean>(true)
    const [filtersVisibleInForm, setFiltersVisibleInForm] = useState<Filters>(initialFilters)
    const [activeSearchFilters, setActiveSearchFilters] = useState<Filters>(initialFilters)

    const [searchInProgress, setSearchInProgress] = useState<boolean>(false)
    const [visibleRequests, setVisibleRequests] = useState<ApiServiceRequest[]>()
    const [dataTableLoading, setDataTableLoading] = useState<boolean>(false);
    const [pagingSettings, setPagingSettings] = useState<PagingSettings>({
        firstResultIndex: 0,
        resultsPerPage: 50,
        currentPage: 0,
        totalRecords: 0
    })
    const [requestDialogOpen, setRequestDialogOpen] = useState<boolean>(false)
    const [requestViewedInDetail, setRequestViewedInDetail] = useState<ApiServiceRequest>()
    const navigate = useNavigate()
    const [searchHistory, updateSearchHistory] = useState<string[]>([])
    const localStorageKey = "APISearchHistory";

    useEffect(() : void => {
        if (!initialLoad || (initialLoad && Object.entries(activeSearchFilters).length > 0)) {
            setURLQueryParametersFromFilters();
            doSearch();
        }

        setInitialLoad(false);
        // eslint-disable-next-line
    }, [activeSearchFilters])

    useEffect(() => {
        updateSearchHistory(getHistory().reverse())
        // eslint-disable-next-line
    }, [])
    
    let loadRequestsBasedOnFiltersAndPagingSettings = (filters: Filters | undefined, settings: PagingSettings) => {

        return searchRequests({
            _page: settings?.currentPage + 1,
            _pageSize: settings?.resultsPerPage,
            application: filters?.application ? filters?.application : undefined,
            httpMethod: filters?.httpMethod ? filters?.httpMethod : undefined,
            httpStatusCode: filters?.httpStatusCode ? filters?.httpStatusCode : undefined,
            incomingUrl: filters?.incomingUrl ? filters?.incomingUrl : undefined,
            outgoingUrl: filters?.outgoingUrl ? filters?.outgoingUrl : undefined,
            requestBody: filters?.requestBody ? filters?.requestBody : undefined,
            requestHeaders: filters?.requestHeaders ? filters?.requestHeaders : undefined,
            responseBody: filters?.responseBody ? filters?.responseBody : undefined,
            responseHeaders: filters?.responseHeaders ? filters?.responseHeaders : undefined,
            startDatetimeFrom: jsDateToIsoStringWithoutTimezone(calendarValueToJsDate(filters?.startDatetimeFrom)),
            startDatetimeUntil: jsDateToIsoStringWithoutTimezone(calendarValueToJsDate(filters?.startDatetimeUntil)),
            userEmail: filters?.userEmail ? filters?.userEmail : undefined
        })
            .then(response => {
                let results = response.results.map(result => {
                    return {
                        ...result,
                        startDatetime: result.startDatetime ? DateTime.fromISO(result.startDatetime).setLocale("nl") : undefined,
                        endDatetime: result.endDatetime ? DateTime.fromISO(result.endDatetime).setLocale("nl") : undefined
                    }
                });
                setVisibleRequests(results)
                return response;
            })
    }

    const handleFiltersChange = (newFilters: Filters) => {
        setFiltersVisibleInForm(newFilters)
    }

    function getHistory(): string[] {
        try {
            let storage = localStorage.getItem(localStorageKey) || "[]";
            return JSON.parse(storage);
        } catch (e) {
            return new Array(0);
        }
    }

    function saveQueryHistory(searchParams: string) {
        if (searchParams === '') return

        let searchHistory = getHistory();

        if (searchHistory.length > 9) searchHistory.shift();

        if (searchParams !== searchHistory[searchHistory.length - 1])
            searchHistory.push(searchParams);

        let reversedCopy = [...searchHistory].reverse()

        updateSearchHistory(reversedCopy)
        localStorage.setItem(localStorageKey, JSON.stringify(searchHistory))

    }

    function setURLQueryParametersFromFilters() {
        if (Object.entries(activeSearchFilters).length === 0) return
        const searchParams = new URLSearchParams(Object.entries(activeSearchFilters)
            .filter(filter => filter[1])
            .map((k =>
                    typeof k[1] == 'object' ? [k[0], toBelgiumDateTime(DateTime.fromJSDate(k[1]))] : k
            ))).toString();

        navigate({
            search: searchParams
        })
        saveQueryHistory(searchParams);
    }

    function onHistoryChange(selectedHistory: string) {
        let optionalQueryParametersFromURL = getOptionalQueryParametersFromURL(selectedHistory);
        setActiveSearchFilters(optionalQueryParametersFromURL)
        setFiltersVisibleInForm(optionalQueryParametersFromURL)
    }
    
    function handleSearchInitiated() {
        // We allow mistyping dates, but they will be cleared upon searching
        const filterWithInvalidDatesRemoved = {
            ...filtersVisibleInForm,
            startDatetimeFrom: calendarValueToJsDate(filtersVisibleInForm.startDatetimeFrom),
            startDatetimeUntil: calendarValueToJsDate(filtersVisibleInForm.startDatetimeUntil)
        }
        setFiltersVisibleInForm(filterWithInvalidDatesRemoved)
        setActiveSearchFilters(filterWithInvalidDatesRemoved)
    }

    const doSearch = (): void => {

        setSearchInProgress(true)
        setDataTableLoading(true)
        loadRequestsBasedOnFiltersAndPagingSettings(activeSearchFilters, pagingSettings)
            .then((response) => {
                setPagingSettings((previousState: PagingSettings) => (
                    {
                        ...previousState,
                        currentPage: 0,
                        firstResultIndex: 0,
                        totalRecords: response.totalRecords
                    }
                ))
                setSearchInProgress(false)
                setDataTableLoading(false)
            }).catch(response => {
            console.error("Failed call because: " + response.code)
            setSearchInProgress(false)
            setDataTableLoading(false)
        });
    }

    function handleAbortSearch() {
        abortLastSearchRequestsCall();
    }

    function onPageChange(event: PaginatorPageChangeEvent) {

        setDataTableLoading(true)

        let updatedPagingSettings = {
            ...pagingSettings,
            firstResultIndex: event.first,
            currentPage: event.page
        };

        setPagingSettings(updatedPagingSettings)
        // the new state will be loaded next rerender, but we need to load the new page now
        loadRequestsBasedOnFiltersAndPagingSettings(filtersVisibleInForm, updatedPagingSettings)
            .then(() => {
                setDataTableLoading(false)
            })
    }

    function onViewRequestDetail(request: ApiServiceRequest) {
        setRequestDialogOpen(true);
        setRequestViewedInDetail(request)
    }

    function onHideRequestDetailDialog() {
        setRequestViewedInDetail(undefined)
        setRequestDialogOpen(false)
    }

    function getOptionalQueryParametersFromURL(url: string) {
        
        const queryParameters = new URLSearchParams(url);

        if(Array.from(queryParameters.keys()).length === 0) {
            return {};
        }
        
        return {
            httpMethod: queryParameters.has("httpMethod") ? "" + queryParameters.get("httpMethod") : "",
            application: queryParameters.has("application") ? "" + queryParameters.get("application") : "",
            httpStatusCode: queryParameters.has("httpStatusCode") ? "" + queryParameters.get("httpStatusCode") : "",
            startDatetimeFrom: queryParameters.has("startDatetimeFrom") ? calendarValueToJsDate(queryParameters.get("startDatetimeFrom")) : "",
            startDatetimeUntil: queryParameters.has("startDatetimeUntil") ? calendarValueToJsDate(queryParameters.get("startDatetimeUntil")) : "",
            incomingUrl: queryParameters.has("incomingUrl") ? "" + queryParameters.get("incomingUrl") : "",
            outgoingUrl: queryParameters.has("outgoingUrl") ? "" + queryParameters.get("outgoingUrl") : "",
            requestBody: queryParameters.has("requestBody") ? "" + queryParameters.get("requestBody") : "",
            requestHeaders: queryParameters.has("requestHeaders") ? "" + queryParameters.get("requestHeaders") : "",
            responseBody: queryParameters.has("responseBody") ? "" + queryParameters.get("responseBody") : "",
            responseHeaders: queryParameters.has("responseHeaders") ? "" + queryParameters.get("responseHeaders") : "",
            userEmail: queryParameters.has("userEmail") ? "" + queryParameters.get("userEmail") : "",
        }
    }
    
    function copyFiltersToClipBoard() {
        let url = window.location.href;
        navigator.clipboard.writeText(url)
        toast.current.show({
            severity: 'info',
            summary: intl.formatMessage({id: "text-copied"}),
            life: 1000
        });
    }

    return (
        <>
            <Toast position="top-center" ref={toast}/>

            <PageTitleBar title={intl.formatMessage({id: "requests.title"})}/>
            <PageContent>

                <ApiServiceRequestSearchFilters
                    filters={filtersVisibleInForm}
                    historyOptions={searchHistory}
                    searchInProgress={searchInProgress}
                    onFiltersUpdated={(newFilters: Filters) => handleFiltersChange(newFilters)}
                    onSearchInitiated={handleSearchInitiated}
                    onAbortSearch={handleAbortSearch}
                    onHistorySelected={onHistoryChange}
                />
                <div className={"flex-row col flex justify-content-end"}>
                    <span className={"fake-link"} onClick={copyFiltersToClipBoard}><i
                        className="pi pi-share-alt mr-1"></i>{intl.formatMessage({id: "share-link"})}</span>
                </div>
                <PagedDataTable
                    value={visibleRequests}
                    loading={dataTableLoading}
                    pagingSettings={pagingSettings}
                    onPageChange={e => onPageChange(e)}>

                    <Column field="application"
                            header={intl.formatMessage({id: "requests.table.header.application"})}
                            body={((result: ApiServiceRequest) => {
                                return <ApplicationBadge application={result.application}/>
                            })}/>
                    <Column field="httpMethod"
                            header={intl.formatMessage({id: "requests.table.header.method"})}/>
                    <Column field="incomingUrl"
                            header={intl.formatMessage({id: "requests.table.header.incomingUrl"})}/>
                    <Column field="httpStatusCode"
                            header={intl.formatMessage({id: "requests.table.header.statusCode"})}
                            body={(result: ApiServiceRequest) => {
                                return <HttpStatusBadge value={Number(result.httpStatusCode)}/>
                            }}/>
                    <Column field="userEmail" header={intl.formatMessage({id: "requests.table.header.user"})}/>
                    <Column field="startDatetime"
                            header={intl.formatMessage({id: "requests.table.header.startedAt"})}
                            body={((result: ApiServiceRequest) => {
                                return toBelgiumDateTime(result.startDatetime)
                            })}/>
                    <Column field="endDatetime" header={intl.formatMessage({id: "requests.table.header.endedAt"})}
                            body={((result: ApiServiceRequest) => {
                                return toBelgiumDateTime(result.endDatetime)
                            })}/>

                    <Column headerStyle={{width: '1rem'}} body={(request: ApiServiceRequest) => {
                        return <Button icon="pi pi-eye" className="p-button-sm p-button-icon"
                                       onClick={() => onViewRequestDetail(request)}/>;
                    }}/>
                </PagedDataTable>

                <ApiServiceRequestDetailDialog request={requestViewedInDetail}
                                               visible={requestDialogOpen}
                                               onHide={onHideRequestDetailDialog}/>

            </PageContent>
        </>
    )
}
