import React from "react";
import {Badge} from "primereact/badge";

interface ApplicationBadgeProps {
    application: string
}

const determineApplicationColors = (application: string) => {
    if (application.toUpperCase() === 'FMS') {
        return { backgroundColor: '#B98DD9', color: '#ffffff' }
    }

    if (application.toUpperCase() === 'MRT') {
        return { backgroundColor: '#7CA9E6', color: '#ffffff' }
    }

    if (application.toUpperCase() === 'ORD') {
        return { backgroundColor: '#99E68A', color: '#ffffff' }
    }

    if (application.toUpperCase() === 'REI') {
        return { backgroundColor: '#FF80AA', color: '#ffffff' }
    }

    if (application.toUpperCase() === 'MGT') {
        return { backgroundColor: '#7CCFDC', color: '#ffffff' }
    }

    return { backgroundColor: 'rgba(0, 0, 0, 0.12)', color: 'rgba(0, 0, 0, 0.87)' }
}

const ApplicationBadge = ({application}: ApplicationBadgeProps) => {
    return (
        <Badge value={application.toUpperCase()} style={determineApplicationColors(application)} />
    )
}

export default ApplicationBadge
