import {SftpUserDTO, updateSftpUser, ValidationError} from "./FileServiceService";
import React, {useEffect} from "react";
import {Dialog} from "primereact/dialog";
import {useIntl} from "react-intl";
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";
import {LocalizationState, selectLanguage} from "../common/localization/LanguageSlice";
import {useAppSelector} from "../app/hooks";
import {FieldValidationError} from "../common/model";

interface UpdateSshKeyDialogProps {
    visible: boolean
    sftpUser?: SftpUserDTO,

    onClose(): void

    onSubmit(): void
}

interface UpdateSshKeyFormData {
    sshKey?: string
}

const emptyForm: UpdateSshKeyFormData = {
    sshKey: ''
}

const UpdateSshKeyDialog = ({visible, sftpUser, onClose, onSubmit}: UpdateSshKeyDialogProps) => {
    
    const [formState, setFormState] = React.useState<UpdateSshKeyFormData>(emptyForm);
    const [validationErrors, setValidationErrors] = React.useState<FieldValidationError[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)

    const intl = useIntl()
    const languageState: LocalizationState = useAppSelector(selectLanguage)

    useEffect(() => {
        setFormState(sftpUser
            ? {
                sshKey: sftpUser.sshKey
            }
            : emptyForm)
    }, [sftpUser])

    const onHide = () => {
        setValidationErrors([])
        setLoading(false)
        onClose()
    }

    const footerContent = (
        <div>
            <Button label={intl.formatMessage({id: "sftp.user.change.sshkey.action.cancel"})}
                    icon="pi pi-times"
                    onClick={onHide}
                    className="p-button-text"/>
            <Button loading={loading}
                    label={intl.formatMessage({id: "sftp.user.change.sshkey.action.submit"})}
                    icon="pi pi-check"
                    onClick={() => {
                        setLoading(true)
                        updateSftpUser(
                            sftpUser?.id as string,
                            {
                                ...sftpUser!!,
                                sshKey: formState.sshKey
                            }
                        ).then(() => {
                            setValidationErrors([])
                            setLoading(false)
                            onSubmit()
                        }).catch(error => {
                            setLoading(false)
                            if (error.response.status === 400) {
                                setValidationErrors(error.response.data
                                    .map((err: ValidationError) => {
                                        return {
                                            field: err.fieldName,
                                            message: err.translations[languageState.selectedLanguage]
                                        }
                                    }))
                            } else {
                                throw new Error(`Request failed with status code ${error.response.status}`);
                            }
                        })
                    }} />
        </div>
    )

    return (
        <Dialog
            header={intl.formatMessage({id: 'sftp.user.change.sshkey.dialog.header'})}
            visible={visible}
            style={{width: '50vw'}}
            onHide={onHide}
            footer={footerContent}>
            <div className="m-0"/>

            <div className="field grid">
                <label htmlFor="application" className="col-12 mb-2 md:col-2 md:mb-0">
                    {intl.formatMessage({id: "sftp.user.create.dialog.label.application"})}
                </label>
                <div className="col-12 md:col-10">
                    {sftpUser?.application}
                </div>
            </div>

            <div className="field grid">
                <label htmlFor="username"
                       className="col-12 mb-2 md:col-2 md:mb-0">
                    {intl.formatMessage({id: "sftp.user.create.dialog.label.username"})}
                </label>
                <div className="col-12 md:col-10">
                    {sftpUser?.userName}
                </div>
            </div>
            <div className="field grid">
                <label htmlFor="sshKey"
                       className="col-12 mb-2 md:col-2 md:mb-0">{intl.formatMessage({id: "sftp.user.create.dialog.label.ssh-key"})}</label>
                <div className="col-12 md:col-10">
                    <InputTextarea id="sshKey"
                                   rows={1}
                                   autoResize
                                   placeholder="ssh-rsa .."
                                   className={`w-full ${validationErrors.find(value => value.field === 'sshKey') ? 'p-invalid' : ''}`}
                                   value={formState.sshKey}
                                   onChange={e => setFormState({
                                       ...formState,
                                       sshKey: e.target.value
                                   })}
                    />
                    <small id="sshKey-validation-error" className="p-error">
                        {validationErrors.find(value => value.field === 'sshKey')?.message}
                    </small>
                </div>
            </div>
        </Dialog>
    )
}

export default UpdateSshKeyDialog
