import './App.css';

import "./app-theme.css"; // Roots theme ??
import "primeflex/primeflex.min.css";
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import {Route, Routes} from "react-router-dom";
import {OutletLayout} from "./common/OutletLayout";
import {ApiServiceRequestPage} from "./api-service/ApiServiceRequestPage";
import {HomePage} from "./HomePage";
import {TransformationServiceRequestPage} from "./transformation-service/TransformationServiceRequestPage";

import {IntlProvider} from "react-intl";
import {LocalizationState, selectLanguage} from "./common/localization/LanguageSlice";
import {useAppSelector} from "./app/hooks";
import SftpUsersPage from "./file-service/SftpUsersPage";
import FmsConfigPage from "./file-service/FmsConfigPage";
import ReiConfigPage from "./file-service/ReiConfigPage";
import MrtConfigPage from "./file-service/MrtConfigPage";
import OrdConfigPage from "./file-service/OrdConfigPage";

function App() {
    const languageState: LocalizationState = useAppSelector(selectLanguage)

    return (
        <IntlProvider locale={languageState.selectedLanguage} messages={languageState.messageSource}>
            <Routes>
                <Route path="/" element={<OutletLayout/>}>
                    <Route index path="" element={<HomePage/>}/>
                    <Route index path="api-service" element={<ApiServiceRequestPage/>}/>
                    <Route index path="transformation-service" element={<TransformationServiceRequestPage/>}/>
                    <Route index path="file-service/sftp-user" element={<SftpUsersPage/>}/>
                    <Route index path="file-service/fms" element={<FmsConfigPage/>}/>
                    <Route index path="file-service/mrt" element={<MrtConfigPage/>}/>
                    <Route index path="file-service/ord" element={<OrdConfigPage/>}/>
                    <Route index path="file-service/rei" element={<ReiConfigPage/>}/>
                </Route>
            </Routes>
        </IntlProvider>
    );
}

export default App;
