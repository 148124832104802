import axios, {AxiosResponse} from "axios";

axios.defaults.withCredentials = true


export interface TransformationServiceSearchRequest {
    _page?: number
    _pageSize?: number
    httpMethod?: string
    correlationId?: string
    httpStatusCode?: string
    startDatetimeFrom?: string
    startDatetimeUntil?: string
    url?: string
    requestBody?: string
    requestHeaders?: string
    responseBody?: string
    responseHeaders?: string
}

export interface TransformationServiceSearchResult {
    httpMethod: string
    correlationId: string
    httpStatusCode: string
    startDatetime: string
    endDatetime: string
    url: string
    requestBody: string
    requestHeaders: string
    responseBody: string
    responseHeaders: string
    hasFile: boolean
}

export interface TransformationServiceSearchResponse {
    results: TransformationServiceSearchResult[],
    totalRecords: number
}
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export function searchRequests(request: TransformationServiceSearchRequest): Promise<TransformationServiceSearchResponse> {
    return axios.get<TransformationServiceSearchResult[]>(window.REACT_APP_API_ENDPOINT + "/transformation-service/search", {params: request, cancelToken : source.token})
        .then(response => {
            return {
                results: response.data,
                totalRecords: +(response.headers["resultcount"] as unknown as number)
            }
        });
}

export function listFiles(correlationId: string): Promise<string[]> {
    return axios.get<string[]>(window.REACT_APP_API_ENDPOINT + "/transformation-service/" + correlationId + "/files")
        .then(response => response.data);
}

export function abortLastSearchRequestsCall(){
    source.cancel("Force abort call");
    source = CancelToken.source();
}

export function getHttpStatusCodes(): Promise<AxiosResponse<string[]>> {
    return axios.get<string[]>(window.REACT_APP_API_ENDPOINT + "/transformation-service/search/http-status-code");
}

export function getHttpMethods(): Promise<AxiosResponse<string[]>> {
    return axios.get<string[]>(window.REACT_APP_API_ENDPOINT + "/transformation-service/search/http-method");
}
