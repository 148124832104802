import {DateTime} from "luxon";
import {parse, stringify} from "lossless-json";

export const formatHeaders = (input: string) => {
    const keyValuePairs = [];

    input = input.slice(1, -1); // Removes the []

    const regex = /([^:\s]+):\s*"([^"]*)"/g;
    let match;
    while ((match = regex.exec(input))) {
        keyValuePairs.push([match[1], match[2]]);
    }

    if (keyValuePairs.length > 0) {
        let finalResult = ""
        keyValuePairs.forEach(entry => {
            finalResult += entry[0] + ": " + entry[1] + "\n"
        })
        return finalResult
    }

    return ""
}

export const currentDateAsFilenameSafeString = () => {
    return DateTime.now()
        .toISO()
        .slice(0, -6) // Chop off timezone
        .replace(/\D/g, '') // Remove all non-numeric characters
}

function hasXmlStructure(input: string) {
    return /<\/?[A-z][\s\S]*>/i.test(input);
}

function hasJsonStructure(str: string) {
    try {
        const result = JSON.parse(str);
        const type = Object.prototype.toString.call(result);
        return type === '[object Object]'
            || type === '[object Array]';
    } catch (err) {
        return false;
    }
}

export const determineExtension = (content: string) => {

    if (hasJsonStructure(content)) {
        return "json"
    }

    if (hasXmlStructure(content)) {
        return "xml"
    }

    return "txt"
}

const prettifyXml = function (sourceXml: string) {
    const prettifyingXlst = new XSLTProcessor();
    prettifyingXlst.importStylesheet(new DOMParser().parseFromString([
        // describes how we want to modify the XML - indent everything
        '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform" version="1">',
        '  <xsl:strip-space elements="*"/>',
        '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
        '    <xsl:value-of select="normalize-space(.)"/>',
        '  </xsl:template>',
        '  <xsl:template match="node()|@*">',
        '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
        '  </xsl:template>',
        '  <xsl:output indent="yes"/>',
        '</xsl:stylesheet>',
    ].join('\n'), 'application/xml'));

    const doc = new DOMParser().parseFromString(sourceXml, 'application/xml');

    const errorNode = doc.querySelector('parsererror');
    
    if(errorNode) {
        return sourceXml;
    }
    
    const resultDoc = prettifyingXlst.transformToDocument(doc);
    return new XMLSerializer().serializeToString(resultDoc);
};

export const prettyPrint = (content: string) => {

    if (hasXmlStructure(content)) {
        return prettifyXml(content)
    }

    if (hasJsonStructure(content)) {
        return stringify(parse(content), null, 2)
    }

    return content;
}

export function todayAtMidnight() {
    return DateTime.now().startOf("day").toJSDate();
}

export function tomorrowAtMidnight() {
    return DateTime.now().plus({"day": 1}).startOf("day").toJSDate();
}

export function calendarValueToJsDate(input: string | Date | Date[] | null | undefined) {

    if (input === null || input === undefined) {
        return undefined;
    }

    if (typeof input === 'string') {
        const parsed = DateTime.fromFormat(input.trim(), "dd/MM/yyyy HH:mm:ss");
        if (parsed.isValid) {
            return parsed.toJSDate();
        } else {
            return undefined;
        }
    }

    if (Array.isArray(input)) {
        throw new Error('An array of dates is not supported.');
    }

    return input;
}

export function jsDateToIsoStringWithoutTimezone(date: Date | undefined) {
    if(date === undefined) {
        return undefined
    }
    const dt = DateTime.fromJSDate(date);
    return dt.toFormat('yyyy-MM-dd') + "T" + dt.toFormat('HH:mm:ss');
}

export function toBelgiumDateTime(dateTime?: DateTime) {
    if(dateTime === undefined) {
        return ""
    }
    return dateTime.toFormat("dd/MM/yyyy HH:mm:ss");
}

export const downloadRaw = (content: string, request: boolean) => {

    const filename = `${currentDateAsFilenameSafeString()}_${request ? "request" : "response"}.${determineExtension(content)}`

    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}