import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {determineMessageSource, getCurrentLanguage} from "./LocalizationService";


export interface LocalizationState {
    selectedLanguage: string
    messageSource: Record<string, string>;
}

const initialState: LocalizationState = {
    selectedLanguage: getCurrentLanguage(),
    messageSource: determineMessageSource(getCurrentLanguage())
}

export const localizationSlice = createSlice({
    name: "language",
    initialState,
    reducers: {
        setLanguage: (state: Draft<LocalizationState>, action: PayloadAction<LocalizationState>) => {
            state.selectedLanguage = action.payload.selectedLanguage;
            state.messageSource = action.payload.messageSource;
        }
    }
})

export const { setLanguage } = localizationSlice.actions

export const selectLanguage = (state: RootState) => state.language

export default localizationSlice.reducer;
