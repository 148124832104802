import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {useIntl} from "react-intl";
import {resetPassword} from "./FileServiceService";
import React from "react";

interface SubjectSftpUser {
    id: string
    email: string
}

interface ResetPasswordDialogProps {

    visible: boolean
    sftpUser?: SubjectSftpUser

    onResetSuccessful(): void

    onClose(): void
}

const ResetPasswordDialog = ({visible, sftpUser, onResetSuccessful, onClose}: ResetPasswordDialogProps) => {

    const intl = useIntl()

    const [loading, setLoading] = React.useState<boolean>(false)

    const footerContent = (
        <div>
            <Button label={intl.formatMessage({id: "sftp.user.reset.password.action.cancel"})} icon="pi pi-times"
                    onClick={() => onClose()} className="p-button-text"/>
            <Button loading={loading}
                    label={intl.formatMessage({id: "sftp.user.reset.password.action.reset.password"})}
                    icon="pi pi-check" onClick={() => {
                setLoading(true)
                resetPassword(sftpUser?.id as string)
                    .then(() => {
                        setLoading(false)
                        onResetSuccessful()
                    })
            }} />
        </div>
    );

    return (
        <Dialog header={intl.formatMessage({id: "sftp.user.reset.password.dialog.header"})}
                visible={visible}
                style={{width: '50vw'}}
                onHide={() => onClose()}
                footer={footerContent}>
            <p className="m-0">
                {intl.formatMessage({id: "sftp.user.reset.password.dialog.explanation"})}
            </p>
            <p>
                {intl.formatMessage({id: "sftp.user.reset.password.dialog.email.label"})}: <b>{sftpUser?.email}</b>
            </p>
        </Dialog>
    )
}

export default ResetPasswordDialog