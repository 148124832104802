import {FileServicePageTitleBar} from "./FileServicePageTitleBar";
import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import {loadMrtConfigurations, MrtConfigurationDTO} from "./FileServiceService";
import PageContent from "../common/PageContent";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import EnabledBadge from "../common/EnabledBadge";

const MrtConfigPage = () => {

    const intl = useIntl();
    const [mrtConfigurations, setMrtConfigurations] = React.useState<MrtConfigurationDTO[]>([]);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        loadMrtConfigurations().then(mrtConfig => {
            setMrtConfigurations(mrtConfig);
            setLoading(false);
        })
    }, []);

    return (
        <>
            <FileServicePageTitleBar title={intl.formatMessage({id: "mrt-config.title"})}/>
            <PageContent>
                <div className="border-1 border-black-alpha-20">
                    <DataTable value={mrtConfigurations} stripedRows loading={loading} scrollable scrollHeight="calc(100vh - 328px)">
                        <Column field="rsNumber"
                                header={intl.formatMessage({id: "config.table.header.rs-number"})}/>
                        <Column field="companyName"
                                header={intl.formatMessage({id: "config.table.header.company-name"})}/>
                        <Column field="email" header={intl.formatMessage({id: "config.table.header.email"})}/>
                        <Column field="logonEnabled"
                                header={intl.formatMessage({id: "config.table.header.logon-enabled"})}
                                align={"center"}
                                body={(dto: MrtConfigurationDTO) => {
                                    return <EnabledBadge value={dto.logonEnabled}/>
                                }}
                        />
                        <Column field="overwriteCleanUpDays"
                                header={intl.formatMessage({id: "config.table.header.overwrite-clean-up-days"})}/>
                        <Column field="publicOverwrite"
                                header={intl.formatMessage({id: "config.table.header.public-overwrite"})}/>
                        <Column field="extraCopiesTo"
                                header={intl.formatMessage({id: "mrt-config.table.header.extra-copies-to"})}/>
                        <Column field="matrices"
                                header={intl.formatMessage({id: "mrt-config.table.header.matrices"})}
                                align={"center"}
                                body={(dto: MrtConfigurationDTO) => {
                                    return <EnabledBadge value={dto.matrices}/>
                                }}/>
                        <Column field="matricesRetourFile"
                                header={intl.formatMessage({id: "mrt-config.table.header.matrices.retour.file"})}
                                align={"center"}
                                body={(dto: MrtConfigurationDTO) => {
                                    return <EnabledBadge value={dto.matricesRetourFile}/>
                                }}/>
                    </DataTable>
                </div>
            </PageContent>
        </>
    )
}

export default MrtConfigPage;
