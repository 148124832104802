import PageTitleBar, {ActionButton} from "../common/PageTitleBar";
import React from "react";
import {useLocation} from "react-router-dom";

interface FileServicePageTitleBarProps {
    title: string
}

export const FileServicePageTitleBar = ({title}: FileServicePageTitleBarProps) => {
    
    const location = useLocation();
    
    return <PageTitleBar title={title}>
        <ActionButton url="/file-service/sftp-user" active={location.pathname === '/file-service/sftp-user'} text="Users" />
        <ActionButton url="/file-service/fms" active={location.pathname === '/file-service/fms'} text="FMS"/>
        <ActionButton url="/file-service/mrt" active={location.pathname === '/file-service/mrt'} text="MRT"/>
        <ActionButton url="/file-service/ord" active={location.pathname === '/file-service/ord'} text="ORD"/>
        <ActionButton url="/file-service/rei" active={location.pathname === '/file-service/rei'} text="REI"/>
    </PageTitleBar>
}