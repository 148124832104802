import React from "react";
import {Badge} from "primereact/badge";

interface HttpStatusBadgeProps {
    value: Number
}

const determineStatusSeverity = (value: Number): 'success' | 'info' | 'warning' | 'danger' | null | undefined => {
    if (value >= 500) {
        return 'danger'
    }

    if (value >= 400) {
        return 'warning'
    }

    if (value >= 300) {
        return 'info'
    }

    if (value >= 200) {
        return 'success'
    }

    if (value >= 100) {
        return 'info'
    }

    return null
}

const HttpStatusBadge = ({value}: HttpStatusBadgeProps) => {
    return (
        value < 100 ?
            <span/> :
            <Badge value={value} severity={determineStatusSeverity(value)}/>
    )
}

export default HttpStatusBadge
