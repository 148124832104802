import axios, {AxiosResponse} from "axios";

axios.defaults.withCredentials = true

export interface RequestSearchFilter {
    _pageSize: number;
    _page: number;
    userEmail?: string;
    application?: string;
    httpStatusCode?: string;
    httpMethod?: string;
}

export interface ApiServiceSearchRequest {
    _page?: number
    _pageSize?: number
    httpMethod?: string
    application?: string
    httpStatusCode?: string
    startDatetimeFrom?: string
    startDatetimeUntil?: string
    incomingUrl?: string
    outgoingUrl?: string
    requestBody?: string
    requestHeaders?: string
    responseBody?: string
    responseHeaders?: string
    userEmail?: string
}

export interface ApiServiceSearchResult {
    id: string
    httpMethod: string
    application: string
    httpStatusCode: string
    startDatetime: string
    endDatetime: string
    incomingUrl: string
    outgoingUrl: string
    requestBody: string
    requestHeaders: string
    responseBody: string
    responseHeaders: string
    userEmail: string
    hasFile: boolean
}

export interface ApiServiceSearchResponse {
    results: ApiServiceSearchResult[],
    totalRecords: number
}

const CancelToken = axios.CancelToken;
let source = CancelToken.source();
export function searchRequests(request: ApiServiceSearchRequest): Promise<ApiServiceSearchResponse> {
    return axios.get<ApiServiceSearchResult[]>(window.REACT_APP_API_ENDPOINT + "/api-service/search", {params: request, cancelToken : source.token})
        .then(response => {
            return {
                results: response.data,
                totalRecords: +(response.headers["resultcount"] as unknown as number)
            }
        });
}

export function listFiles(id: string): Promise<string[]> {
    return axios.get<string[]>(window.REACT_APP_API_ENDPOINT + "/api-service/" + id + "/files")
        .then(response => response.data);
}

export function abortLastSearchRequestsCall(){
    source.cancel("Force abort call");
    source = CancelToken.source();
}

export function getHttpStatusCodes(): Promise<AxiosResponse<string[]>> {
    return axios.get<string[]>(window.REACT_APP_API_ENDPOINT + "/api-service/search/http-status-code");
}

export function getHttpMethods(): Promise<AxiosResponse<string[]>> {
    return axios.get<string[]>(window.REACT_APP_API_ENDPOINT + "/api-service/search/http-method");
}

export function getApplications(): Promise<AxiosResponse<string[]>> {
    return axios.get<string[]>(window.REACT_APP_API_ENDPOINT + "/api-service/search/application");
}
