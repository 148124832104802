import {FileServicePageTitleBar} from "./FileServicePageTitleBar";
import React, {useEffect} from "react";
import PageContent from "../common/PageContent";
import {DataTable} from "primereact/datatable";
import {useIntl} from "react-intl";
import {Column} from "primereact/column";
import {loadOrdConfigurations, OrdConfigurationDTO} from "./FileServiceService";
import EnabledBadge from "../common/EnabledBadge";

const OrdConfigPage = () => {

    const intl = useIntl();
    const [loading, setLoading] = React.useState(false);
    const [ordConfigurations, setOrdConfigurations] = React.useState<OrdConfigurationDTO[]>([]);

    useEffect(() => {
        setLoading(true);
        loadOrdConfigurations().then(config => {
                setOrdConfigurations(config);
                setLoading(false);
            }
        )
    }, []);

    return (
        <>
            <FileServicePageTitleBar title={intl.formatMessage({id: "ord-config.title"})}/>
            <PageContent>
                <div className="border-1 border-black-alpha-20">
                    <DataTable value={ordConfigurations} stripedRows loading={loading} scrollable scrollHeight="calc(100vh - 328px)">
                        <Column field="rsNumber" header={intl.formatMessage({id: "config.table.header.rs-number"})}/>
                        <Column field="companyName"
                                header={intl.formatMessage({id: "config.table.header.company-name"})}/>
                        <Column field="email" header={intl.formatMessage({id: "config.table.header.email"})}/>
                        <Column field="logonEnabled"
                                header={intl.formatMessage({id: "config.table.header.logon-enabled"})}
                                style={{textAlign: "center"}}
                                body={(dto: OrdConfigurationDTO) => {
                                    return <EnabledBadge value={dto.logonEnabled}/>
                                }}
                        />
                        <Column field="overwriteCleanUpDays"
                                header={intl.formatMessage({id: "config.table.header.overwrite-clean-up-days"})}/>
                        <Column field="supplierListProvided"
                                header={intl.formatMessage({id: "ord-config.table.header.supplier-list-provided"})}
                                body={(dto: OrdConfigurationDTO) => {
                                    if(dto.supplierListProvided) {
                                        return intl.formatMessage({id: "ord-config.supplier-list-provided." + dto.supplierListProvided});
                                    }
                                    
                                    return ""
                                }}
                        />
                    </DataTable>
                </div>
            </PageContent>
        </>
    )
}

export default OrdConfigPage
