import PageContent from "./common/PageContent";
import PageTitleBar from "./common/PageTitleBar";

export const HomePage = () => {
    return (
        <>
            <PageTitleBar title="Home"/>
            <PageContent>
                <p>Welcome to the CL admin app</p>
            </PageContent>
        </>
    )
}