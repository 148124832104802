import {Divider} from "primereact/divider";
import {Dialog} from "primereact/dialog";
import {TransformationServiceRequest} from "../common/model";
import {downloadRaw, formatHeaders, prettyPrint} from "../common/utilities";
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {listFiles} from "./TransformationServiceRequestService";

interface TransformationServiceRequestDetailDialogProps {
    visible: boolean,
    request?: TransformationServiceRequest,

    onHide(): void
}

const TransformationServiceRequestDetailDialog = ({
                                                      visible,
                                                      request,
                                                      onHide
                                                  }: TransformationServiceRequestDetailDialogProps) => {

    const noHeadersMsg: string = useIntl().formatMessage({id: 'requests.detail.noHeaders'});
    const noPayloadMsg: string = useIntl().formatMessage({id: 'requests.detail.noPayload'});

    const [files, setFiles] = useState<string[]>([])
    const [loadingFiles, setLoadingFiles] = useState<boolean>(false)

    useEffect(() => {

        let cancelled = false;

        if(!request || !request.correlationId) {
            return;
        }

        setLoadingFiles(true);
        if (request.hasFile) {
            listFiles(request.correlationId).then(files => {
                if(!cancelled) {
                    setFiles(files)
                    setLoadingFiles(false);
                }
            })
        } else {
            setLoadingFiles(false);
            setFiles([])
        }

        return () => {
            cancelled = true;
        }
    }, [request]);

    return (
        <Dialog header={"Request Details"}
                visible={visible}
                maximizable
                style={{width: '50vw'}}
                onHide={() => onHide()}>

            <div className="formgrid grid">
                <div className="field col-12">
                    <div className="flex justify-content-between mb-2">
                        <label htmlFor="response">
                            <FormattedMessage id="transformation.detail.incomingRequest"/>
                        </label>
                        {request?.requestBody ? (
                            <button className="p-link text-primary"
                                    onClick={() => {
                                        downloadRaw(request?.requestBody as string, true)
                                    }}>
                                <FormattedMessage id="transformation.detail.download"/>
                            </button>
                        ) : ""}
                    </div>
                    <div className="border-1 border-round p-2 border-primary">
                        <code className="text-base text-color">
                            <pre className="overflow-auto pb-3" style={{
                                "whiteSpace": "pre-wrap"
                            }}>
                                {request?.httpMethod} {request?.url}<br/><br/>
                                {request?.requestHeaders ? formatHeaders(request?.requestHeaders) : noHeadersMsg}
                                <Divider/>
                                {request?.requestBody ? prettyPrint(request.requestBody) : noPayloadMsg}
                            </pre>
                        </code>
                    </div>
                </div>
                <div className="field col-12">
                    <div className="flex justify-content-between mb-2">
                        <label htmlFor="response">
                            <FormattedMessage id="transformation.detail.response"/>
                        </label>
                        {request?.responseBody ? (
                            <button className="p-link text-primary"
                                    onClick={() => {
                                        downloadRaw(request?.responseBody as string, false)
                                    }}>
                                <FormattedMessage id="transformation.detail.download"/>
                            </button>
                        ) : ""}
                    </div>
                    <div className="border-1 border-round p-2 border-primary">
                        <code className="text-base text-color">
                            <pre className="overflow-auto pb-3" style={{
                                "whiteSpace": "pre-wrap"
                            }}>
                                {request?.httpStatusCode}<br/><br/>
                                {request?.responseHeaders ? formatHeaders(request.responseHeaders) : noHeadersMsg}
                                <Divider/>
                                {request?.responseBody ? prettyPrint(request.responseBody) : noPayloadMsg}
                            </pre>
                        </code>
                    </div>
                </div>

                {(loadingFiles || files.length > 0) &&
                    <div className="field col-12">
                        Files<br/>

                        {
                            loadingFiles ? "Loading..." : (
                                <ul>
                                    {files.map(f => <li>
                                        <a
                                            rel="noreferrer"
                                            target={"_blank"}
                                            href={window.REACT_APP_API_ENDPOINT + "/transformation-service/" + request?.correlationId + "/files/" + encodeURIComponent(f)}>
                                            {f}
                                        </a>
                                    </li>)}
                                </ul>)
                        }
                    </div>
                }

            </div>
        </Dialog>
    )
}

export default TransformationServiceRequestDetailDialog;
