import {DataTable} from "primereact/datatable";
import React from "react";
import {FormattedMessage} from "react-intl";
import {Paginator, PaginatorPageChangeEvent} from "primereact/paginator";

export interface PagingSettings {
    firstResultIndex: number
    resultsPerPage: number
    currentPage: number
    totalRecords: number
}

export interface PagedDataTableProps {
    value: any[] | undefined,
    loading: boolean,
    children: React.ReactNode[],
    pagingSettings: PagingSettings

    onPageChange(event: PaginatorPageChangeEvent): void
}

export function PagedDataTable({value, children, loading, onPageChange, pagingSettings}: PagedDataTableProps) {
    return (
        <div className="border-1 border-black-alpha-20">
            <DataTable value={value}
                       sortMode="multiple"
                       stripedRows
                       loading={loading}
                       // scrollable scrollHeight="800px"
                       rows={pagingSettings?.resultsPerPage}>
                {children}
            </DataTable>

            <Paginator className="mt-2"
                       template={{
                           layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
                           'CurrentPageReport': () => {
                               return (
                                   <span className="p-paginator-current">
                                       <FormattedMessage id="common.pagedDataTable.pageReport"
                                                         values={
                                                             {
                                                                 totalRecords: pagingSettings.totalRecords
                                                             }
                                                         }/>
                                   </span>
                               )
                           }
                       }}
                       first={pagingSettings.firstResultIndex}
                       rows={pagingSettings.resultsPerPage}
                       totalRecords={pagingSettings.totalRecords}
                       onPageChange={(event: PaginatorPageChangeEvent) => onPageChange(event)}/>
        </div>)
}
