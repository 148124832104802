import React, {useEffect, useRef} from "react";
import {useIntl} from "react-intl";
import PageContent from "../common/PageContent";
import {FmsConfigurationDTO, loadFmsConfigurations} from "./FileServiceService";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import ResetPasswordDialog from "./ResetPasswordDialog";
import {Toast} from "primereact/toast";
import {FileServicePageTitleBar} from "./FileServicePageTitleBar";
import EnabledBadge from "../common/EnabledBadge";
import {Chip} from "primereact/chip";

interface ResetPasswordState {
    dialogVisible: boolean
    targetConfig?: FmsConfigurationDTO
}

const FmsConfigPage = () => {

    const intl = useIntl()

    const toast = useRef<any>(null)

    const [fmsConfigurations, setFmsConfigurations] = React.useState<FmsConfigurationDTO[]>([])
    const [loading, setLoading] = React.useState(false)
    const [resetPasswordState, setResetPasswordState] = React.useState<ResetPasswordState>({
        dialogVisible: false,
        targetConfig: undefined
    })

    const showResetPasswordDialog = (fmsConfig: FmsConfigurationDTO) => {
        setResetPasswordState({
            ...resetPasswordState,
            dialogVisible: true,
            targetConfig: fmsConfig
        })
    }

    const hideResetPasswordDialog = () => {
        setResetPasswordState({
            ...resetPasswordState,
            dialogVisible: false,
            targetConfig: undefined
        })
    }

    const onResetPasswordSuccessful = () => {
        toast.current.show({
            severity: 'success',
            summary: intl.formatMessage({id: "sftp.user.reset.password.successful.title"}),
            detail: intl.formatMessage({id: "sftp.user.reset.password.successful.description"}),
            life: 2000
        });

        hideResetPasswordDialog()
    }

    useEffect(() => {
        setLoading(true);
        loadFmsConfigurations().then(fmsConfig => {
            setFmsConfigurations(fmsConfig)
            setLoading(false);
        })

        return () => {
        };
    }, []);

    return (
        <>

            <Toast position="top-center" ref={toast}/>

            <FileServicePageTitleBar title={intl.formatMessage({id: "fms-config.title"})}/>
            <PageContent>

                <div className="border-1 border-black-alpha-20">

                    <DataTable value={fmsConfigurations} stripedRows loading={loading} scrollable scrollHeight="calc(100vh - 328px)" >
                        <Column field="rsNumber"
                                header={intl.formatMessage({id: "config.table.header.rs-number"})}/>
                        <Column field="companyName"
                                header={intl.formatMessage({id: "config.table.header.company-name"})}/>
                        <Column field="email" header={intl.formatMessage({id: "config.table.header.email"})}/>
                        <Column
                            field="logonEnabled"
                            header={intl.formatMessage({id: "config.table.header.logon-enabled"})}
                            style={{textAlign: "center"}}
                            body={((result: FmsConfigurationDTO) => {
                                return <EnabledBadge value={result.logonEnabled}/>
                            })}
                        />
                        <Column field="uploadType"
                                header={intl.formatMessage({id: "fms-config.table.header.upload-type"})}
                                body={(result: FmsConfigurationDTO) => {
                                    return <div className="flex flex-row flex-wrap">
                                        <Chip
                                            label={result.uploadType}
                                            className="flex align-items-center justify-content-center m-1"/>
                                    </div>
                                }}/>
                        <Column field="managedServicePickup"
                                header={intl.formatMessage({id: "fms-config.table.header.managed-service-pickup"})}
                                body={(result: FmsConfigurationDTO) => {
                                    return <div className="flex flex-row flex-wrap">
                                        <Chip
                                            label={result.managedServicePickup}
                                            className="flex align-items-center justify-content-center m-1"/>
                                    </div>
                                }}/>
                        <Column field="retributionPickup"
                                header={intl.formatMessage({id: "fms-config.table.header.retribution-pickup"})}
                                body={(result: FmsConfigurationDTO) => {
                                    return <div className="flex flex-row flex-wrap">
                                        <Chip
                                            label={result.retributionPickup}
                                            className="flex align-items-center justify-content-center m-1"/>
                                    </div>
                                }}/>
                        <Column body={(result: FmsConfigurationDTO) => {

                            return result.sftpUserId ? <Button
                                tooltip={intl.formatMessage({id: "fms-config.table.action.reset.password.tooltip"})}
                                tooltipOptions={{position: "bottom"}}
                                icon="pi pi-lock-open"
                                className="p-button-sm p-button-icon"
                                onClick={() => showResetPasswordDialog(result)}/> : "";
                        }}/>
                    </DataTable>
                </div>
            </PageContent>

            <ResetPasswordDialog
                visible={resetPasswordState?.dialogVisible}
                sftpUser={resetPasswordState.targetConfig
                && resetPasswordState.targetConfig.sftpUserId
                && resetPasswordState.targetConfig.email ? {
                    id: resetPasswordState.targetConfig.sftpUserId,
                    email: resetPasswordState.targetConfig.email
                } : undefined}
                onClose={() => hideResetPasswordDialog()}
                onResetSuccessful={() => onResetPasswordSuccessful()}
            />
        </>
    )
}

export default FmsConfigPage
