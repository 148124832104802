import React from 'react';

const PageContent = ({children}: { children: React.ReactNode }) => {
    return (
        <div className="m-auto pl-5 pr-5" style={{width: "80%"}}>
            {children}
        </div>
    )
}

export default PageContent;