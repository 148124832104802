import React, {useEffect, useRef} from "react";
import {useIntl} from "react-intl";
import PageContent from "../common/PageContent";
import {loadSftpUsers, SftpUserDTO} from "./FileServiceService";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import ResetPasswordDialog from "./ResetPasswordDialog";
import {Toast} from "primereact/toast";
import CreateOrUpdateUserDialog from "./CreateOrUpdateUserDialog";
import UpdateSshKeyDialog from "./UpdateSshKeyDialog";
import {FileServicePageTitleBar} from "./FileServicePageTitleBar";
import {Chip} from "primereact/chip";
import ApplicationBadge from "../common/ApplicationBadge";
import EnabledBadge from "../common/EnabledBadge";

interface ResetPasswordState {
    dialogVisible: boolean
    targetSftpUser?: SftpUserDTO
}

interface CreateOrUpdateUserState {
    dialogVisible: boolean
    targetUser?: SftpUserDTO
}

const SftpUsersPage = () => {

    const intl = useIntl()

    const toast = useRef<any>(null)

    const [sftpUsers, setSftpUsers] = React.useState<SftpUserDTO[]>([])
    const [loading, setLoading] = React.useState(false)
    const [resetPasswordState, setResetPasswordState] = React.useState<ResetPasswordState>({
        dialogVisible: false,
        targetSftpUser: undefined
    })

    const [changeSshKeyState, setChangeSshKeyState] = React.useState<ResetPasswordState>({
        dialogVisible: false,
        targetSftpUser: undefined
    })

    const [createOrUpdateUserDialogState, setCreateOrUpdateUserDialogState] = React.useState<CreateOrUpdateUserState>({
        dialogVisible: false,
        targetUser: undefined
    })

    const showResetPasswordDialog = (sftpUser: SftpUserDTO) => {
        setResetPasswordState({
            ...resetPasswordState,
            dialogVisible: true,
            targetSftpUser: sftpUser
        })
    }

    const hideResetPasswordDialog = () => {
        setResetPasswordState({
            ...resetPasswordState,
            dialogVisible: false,
            targetSftpUser: undefined
        })
    }

    const onResetPasswordSuccessful = () => {
        toast.current.show({
            severity: 'success',
            summary: intl.formatMessage({id: "sftp.user.reset.password.successful.title"}),
            detail: intl.formatMessage({id: "sftp.user.reset.password.successful.description"}),
            life: 2000
        });

        hideResetPasswordDialog()
    }

    const showChangeSshKeyDialog = (sftpUser: SftpUserDTO) => {
        setChangeSshKeyState({
            dialogVisible: true,
            targetSftpUser: sftpUser
        })
    }

    const hideChangeSshKeyDialog = () => {
        setChangeSshKeyState({
            dialogVisible: false,
            targetSftpUser: undefined
        })
    }

    const onChangeSshKeySuccessful = () => {
        toast.current.show({
            severity: 'success',
            summary: intl.formatMessage({id: "sftp.user.change.sshkey.successful.title"}),
            detail: intl.formatMessage({id: "sftp.user.change.sshkey.successful.description"}, {
                username: changeSshKeyState.targetSftpUser?.userName,
                application: changeSshKeyState.targetSftpUser?.application
            }),
            life: 2000
        });

        hideChangeSshKeyDialog()
        loadSftpUsersTable()
    }

    function onCreateUserClicked() {
        setCreateOrUpdateUserDialogState({
            dialogVisible: true,
            targetUser: undefined
        })
    }

    function showEditUserDialog(sftpUser: SftpUserDTO) {
        setCreateOrUpdateUserDialogState({
            dialogVisible: true,
            targetUser: sftpUser
        })
    }

    function handleCreateOrUpdateDialogClose() {
        setCreateOrUpdateUserDialogState({
            ...createOrUpdateUserDialogState,
            dialogVisible: false,
        })
    }

    function handleCreateOrUpdateDialogSubmit() {
        setCreateOrUpdateUserDialogState({
            dialogVisible: false,
            targetUser: undefined
        })
        loadSftpUsersTable()
    }

    const loadSftpUsersTable = () => {
        setLoading(true);
        loadSftpUsers()
            .then(sftpUsers => {
                setSftpUsers(sftpUsers)
                setLoading(false);
            })
    }

    useEffect(() => {

        loadSftpUsersTable()

        return () => {
        };
    }, []);

    return (
        <>

            <Toast position="top-center" ref={toast}/>

            <FileServicePageTitleBar title={intl.formatMessage({id: "sftp-users.title"})}/>
            <PageContent>

                <div className="w-full flex justify-content-end flex-wrap pb-3">
                    <div>
                        <Button
                            onClick={() => onCreateUserClicked()}>{intl.formatMessage({id: "sftp.user.action.create-user"})}</Button>
                    </div>
                </div>

                <div className="border-1 border-black-alpha-20">
                    <DataTable value={sftpUsers} stripedRows loading={loading} scrollable scrollHeight="calc(100vh - 328px)">
                        <Column field="userName"
                                header={intl.formatMessage({id: "sftp-users.table.header.user-name"})}/>
                        <Column field="application"
                                header={intl.formatMessage({id: "sftp-users.table.header.application"})}
                                body={((result: SftpUserDTO) => {
                                    return <ApplicationBadge application={result.application}/>
                                })}
                        />
                        <Column field="email" header={intl.formatMessage({id: "sftp-users.table.header.email"})}/>
                        <Column
                            field="logonEnabled"
                            header={intl.formatMessage({id: "sftp-users.table.header.logon-enabled"})}
                            body={((result: SftpUserDTO) => {
                                return <EnabledBadge value={result.logonEnabled}/>
                            })}
                        />
                        <Column field="directories"
                                header={intl.formatMessage({id: "sftp-users.table.header.directories"})}
                                body={((result: SftpUserDTO) => {
                                    return <div className="flex flex-row flex-wrap">
                                        {
                                            result.directories?.map(directory => {
                                                return <Chip label={directory}
                                                             className="flex align-items-center justify-content-center m-1"/>
                                            })
                                        }
                                    </div>
                                })}
                        />
                        <Column body={(sftpUser: SftpUserDTO) => {
                            return <>
                                <Button
                                    tooltip={intl.formatMessage({id: "sftp-users.table.action.reset.password.tooltip"})}
                                    tooltipOptions={{position: "bottom"}}
                                    icon="pi pi-lock-open"
                                    className="p-button-sm p-button-icon mr-2"
                                    onClick={() => showResetPasswordDialog(sftpUser)}/>
                                <Button
                                    tooltip={intl.formatMessage({id: "sftp-users.table.action.change.sshkey.tooltip"})}
                                    tooltipOptions={{position: "bottom"}}
                                    icon="pi pi-key"
                                    className="p-button-sm p-button-icon mr-2"
                                    onClick={() => showChangeSshKeyDialog(sftpUser)}/>
                                {sftpUser.editable ? <Button
                                    tooltip={intl.formatMessage({id: "sftp-users.table.action.edit.user.tooltip"})}
                                    tooltipOptions={{position: "bottom"}}
                                    icon="pi pi-pencil"
                                    className="p-button-sm p-button-icon"
                                    onClick={() => showEditUserDialog(sftpUser)}/> : ""}
                            </>
                        }}/>
                    </DataTable>
                </div>
            </PageContent>

            <CreateOrUpdateUserDialog visible={createOrUpdateUserDialogState?.dialogVisible}
                                      formData={
                                          createOrUpdateUserDialogState?.targetUser
                                              ? {
                                                  id: createOrUpdateUserDialogState.targetUser.id,
                                                  application: createOrUpdateUserDialogState.targetUser.application,
                                                  userName: createOrUpdateUserDialogState.targetUser.userName,
                                                  email: createOrUpdateUserDialogState.targetUser.email,
                                                  logonEnabled: createOrUpdateUserDialogState.targetUser.logonEnabled,
                                                  admin: createOrUpdateUserDialogState.targetUser.admin,
                                                  directories: createOrUpdateUserDialogState.targetUser.directories,
                                                  sshKey: createOrUpdateUserDialogState.targetUser.sshKey
                                              }
                                              : undefined}
                                      onClose={() => handleCreateOrUpdateDialogClose()}
                                      onSubmit={() => handleCreateOrUpdateDialogSubmit()}/>

            <ResetPasswordDialog
                visible={resetPasswordState?.dialogVisible}
                sftpUser={resetPasswordState.targetSftpUser ? {
                    id: resetPasswordState.targetSftpUser.id,
                    email: resetPasswordState.targetSftpUser.email
                } : undefined}
                onClose={() => hideResetPasswordDialog()}
                onResetSuccessful={() => onResetPasswordSuccessful()}
            />

            <UpdateSshKeyDialog
                visible={changeSshKeyState?.dialogVisible}
                sftpUser={changeSshKeyState?.targetSftpUser}
                onClose={hideChangeSshKeyDialog}
                onSubmit={onChangeSshKeySuccessful}
            />
        </>
    )
}

export default SftpUsersPage

