import {Divider} from "primereact/divider";
import {Dialog} from "primereact/dialog";
import {ApiServiceRequest} from "../common/model";
import {downloadRaw, formatHeaders, prettyPrint} from "../common/utilities";
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {listFiles} from "./ApiServiceRequestService";

interface ApiServiceRequestDetailDialogProps {
    visible: boolean,
    request?: ApiServiceRequest,

    onHide(): void
}

const ApiServiceRequestDetailDialog = ({
                                           visible,
                                           request,
                                           onHide
                                       }: ApiServiceRequestDetailDialogProps) => {

    const intl = useIntl();

    const notForwardedMsg: string = intl.formatMessage({id: 'requests.detail.notForwarded'});
    const noHeadersMsg: string = intl.formatMessage({id: 'requests.detail.noHeaders'});
    const noPayloadMsg: string = intl.formatMessage({id: 'requests.detail.noPayload'});

    const [files, setFiles] = useState<string[]>([])
    const [loadingFiles, setLoadingFiles] = useState<boolean>(false)

    useEffect(() => {

        let cancelled = false;

        if(!request || !request.id) {
            return;
        }

        setLoadingFiles(true);
        if (request.hasFile) {
            listFiles(request.id).then(files => {
                if(!cancelled) {
                    setFiles(files)
                    setLoadingFiles(false);
                }
            })
        } else {
            setFiles([])
            setLoadingFiles(false);
        }

        return () => {
            cancelled = true;
        }
    }, [request]);

    return (
        <Dialog header="Request Details" visible={visible}
                maximizable
                style={{width: '50vw'}}
                onHide={() => onHide()}>

            <div className="formgrid grid">
                <div className="field col-12">
                    <div className="flex justify-content-between mb-2">
                        <label htmlFor="response">
                            <FormattedMessage id="requests.detail.incomingRequest"/>
                        </label>
                        {request?.requestBody ? (
                            <button className="p-link text-primary"
                                    onClick={() => {
                                        downloadRaw(request?.requestBody, true)
                                    }}>
                                <FormattedMessage id="requests.detail.download"/>
                            </button>
                        ) : ""}
                    </div>
                    <div className="border-1 border-round p-2 border-primary">
                        <code className="text-base text-color">
                            <pre className="overflow-auto pb-3" style={{
                                "whiteSpace": "pre-wrap"
                            }}>
                                {request?.httpMethod} {request?.incomingUrl}<br/><br/>
                                {request?.requestHeaders ? formatHeaders(request?.requestHeaders) : noHeadersMsg}
                                <Divider/>
                                {request?.requestBody ? prettyPrint(request.requestBody) : noPayloadMsg}
                            </pre>
                        </code>
                    </div>
                </div>
                <div className="field col-12 grid">
                    <label htmlFor="outgoingUrl" className="col-fixed" style={{width: "240px"}}>
                        <FormattedMessage id="requests.detail.requestForwardedTo"/>
                    </label>
                    <div className="col">
                        <span id="outgoingUrl">{request?.outgoingUrl ? request?.outgoingUrl : notForwardedMsg}</span>
                    </div>
                </div>
                <div className="field col-12">
                    <div className="flex justify-content-between mb-2">
                        <label htmlFor="response">
                            <FormattedMessage id="requests.detail.response"/>
                        </label>
                        {request?.responseBody ? (
                            <button className="p-link text-primary"
                                    onClick={() => {
                                        downloadRaw(request?.responseBody, false)
                                    }}>
                                <FormattedMessage id="requests.detail.download"/>
                            </button>
                        ) : ""}
                    </div>
                    <div className="border-1 border-round p-2 border-primary">
                        <code className="text-base text-color">
                            <pre className="pb-3" style={{
                                "whiteSpace": "pre-wrap"
                            }}>
                                {request?.httpStatusCode}<br/><br/>
                                {request?.responseHeaders ? formatHeaders(request.responseHeaders) : noHeadersMsg}
                                <Divider/>
                                {request?.responseBody ? prettyPrint(request.responseBody) : noPayloadMsg}
                            </pre>
                        </code>
                    </div>
                </div>

                {(loadingFiles || files.length > 0) &&
                    <div className="field col-12">
                        Files<br/>
                        {
                            loadingFiles ? "Loading..." : (
                                <ul>
                                    {files.map(f => <li>
                                        <a
                                            rel="noreferrer"
                                            target={"_blank"}
                                            href={window.REACT_APP_API_ENDPOINT + "/api-service/" + request?.id + "/files/" + encodeURIComponent(f)}>
                                            {f}
                                        </a>
                                    </li>)}
                                </ul>)
                        }
                    </div>
                }
            </div>
        </Dialog>
    )
}

export default ApiServiceRequestDetailDialog;
