import "./PageTitleBar.css"
import React from "react";
import {useNavigate} from "react-router-dom";

interface ActionButtonProps {
    text: string,
    active: boolean
    url: string
}

export const ActionButton = ({text, active, url}: ActionButtonProps) => {

    const navigate = useNavigate();

    return (
        <div
            className={"flex flex-1 align-items-center justify-content-center ActionButton" + (active ? " active" : "")}>
            <a href={url} className="p-5" onClick={e => {
                e.preventDefault();
                navigate(url)
            }
            }>{text}</a>
        </div>
    )
}

interface PageTitleBarProps {
    title: string,
    children?: React.ReactNode;
}

const PageTitleBar = ({title, children}: PageTitleBarProps) => {
    return (
        <div className="bg-white w-full h-5rem flex justify-content-center mb-5"
             style={{
                 boxShadow: "0 5px 10px 0 rgb(98 105 115 / 8%)",
                 fontFamily: "Montserrat"
             }}>
            <div className="h-5rem flex justify-content-between align-items-stretch" style={{
                width: "80%",
            }}>
                <div className="h-5rem flex align-items-center" style={{}}>{title}</div>
                <div className="flex align-items-stretch justify-content-evenly">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default PageTitleBar