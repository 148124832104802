import {Outlet, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import TopBarWrapper from "../topbar/TopBarWrapper";
import {determineMessageSource} from "./localization/LocalizationService";
import {useAppDispatch} from "../app/hooks";
import {setLanguage} from "./localization/LanguageSlice";


export function OutletLayout() {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    function navigateTo(event: any, path: string) {
        if (event.ctrlKey)
            window.open(path, "_blank")
        else
            navigate(path)
    }

    useEffect(() => {
        const url = window.location.pathname.split("/");

        let activeItem = "";
        switch (url[1]) {
            case "api-service":
                activeItem = "#APINav";
                break;
            case "transformation-service":
                activeItem = "#TransformationNav";
                break;
            case "file-service":
                activeItem = '[id="File ServiceNav"';
                break;
        }

        if (activeItem.length !== 0) {
            const element = document.querySelector(activeItem);
            if (element == null) return;

            element.className += " active-menu-item";
        }
    })


    return (
        <main className="App">
            <TopBarWrapper onLanguageChange={language => {
                dispatch(setLanguage({
                    selectedLanguage: language,
                    messageSource: determineMessageSource(language)
                }))
            }} application='CL Admin' menuItems={[
                {
                    id: 'api-service-requests',
                    label: "API",
                    callback(event) {
                        navigateTo(event, "/api-service");
                    }
                },
                {
                    id: 'transformation-service-requests',
                    label: "Transformation",
                    callback(event) {
                        navigateTo(event, "/transformation-service");
                    }
                },
                {
                    id: 'file-service',
                    label: "File Service",
                    callback(event) {
                        navigateTo(event, "/file-service/sftp-user");
                    }
                }
            ]}/>
            <Outlet/>
        </main>
    )
}
