import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {useIntl} from "react-intl";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Message} from "primereact/message";
import React, {useEffect} from "react";
import {createSftpUser, updateSftpUser, ValidationError} from "./FileServiceService";
import {LocalizationState, selectLanguage} from "../common/localization/LanguageSlice";
import {useAppSelector} from "../app/hooks";
import {SelectButton} from "primereact/selectbutton";
import {FieldValidationError} from "../common/model";
import ApplicationBadge from "../common/ApplicationBadge";

interface CreateOrUpdateUserDialogProps {
    visible: boolean
    formData?: CreateOrUpdateUserData,

    onClose(): void

    onSubmit(): void
}

export interface CreateOrUpdateUserData {
    id?: string
    application?: string
    userName?: string
    email?: string
    logonEnabled: boolean
    admin: boolean,
    directories?: string[],
    sshKey?: string
}

const emptyForm = {
    logonEnabled: true,
    admin: false,
    directories: [""]
}

const CreateOrUpdateUserDialog = ({visible, formData, onClose, onSubmit}: CreateOrUpdateUserDialogProps) => {

    const [formState, setFormState] = React.useState<CreateOrUpdateUserData>(emptyForm)
    const [validationErrors, setValidationErrors] = React.useState<FieldValidationError[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)

    const languageState: LocalizationState = useAppSelector(selectLanguage)

    useEffect(() => {
        setFormState(formData
            ? {
                ...formData,
                directories: formData.directories ? [...formData.directories, ""] : [""]
            }
            : emptyForm)
    }, [formData])

    const intl = useIntl()
    const updatingExistingUser: boolean = !!formData?.id;

    const selectOptions = [
        {name: intl.formatMessage({id: "common.yes"}), value: true},
        {name: intl.formatMessage({id: "common.no"}), value: false},
    ]

    const handleChange = (index: number, value: string) => {
        const newDirectories = [...formState.directories as string[]];

        // Update value
        newDirectories[index] = value;

        // Remove empty items
        const filteredDirectories = newDirectories.filter(
            (directory, i) => directory.trim() !== "" || i === newDirectories.length - 1
        );

        // Add an empty item at the end if there is none
        const lastItem = filteredDirectories[filteredDirectories.length - 1];
        if (lastItem && lastItem.trim() !== "") {
            filteredDirectories.push("");
        }

        setFormState({...formState, directories: filteredDirectories});
    };

    function onFormSubmit() {

        setValidationErrors([])
        setLoading(true)

        const directories = formState.admin ? undefined : formState.directories ? formState.directories.filter(dir => dir.trim() !== "") : undefined;

        const backendCall = updatingExistingUser ?

            updateSftpUser(formState.id as string, {
                directories: directories,
                email: formState.email as string,
                logonEnabled: formState.logonEnabled,
                sshKey: formState.sshKey
            })
            : createSftpUser({
                admin: formState.admin,
                application: formState.application as string,
                userName: formState.userName as string,
                email: formState.email as string,
                logonEnabled: formState.logonEnabled,
                directories: directories,
                sshKey: formState.sshKey
            });

        backendCall.then(() => {
            setLoading(false)
            setFormState(emptyForm)
            onSubmit()
        }).catch(error => {
            setLoading(false)
            if (error.response.status === 400) {
                setValidationErrors(error.response.data
                    .map((err: ValidationError) => {
                        return {
                            field: err.fieldName,
                            message: err.translations[languageState.selectedLanguage]
                        }
                    }))
            } else {
                throw new Error(`Request failed with status code ${error.response.status}`);
            }
        });
    }

    const onHide = () => {
        setValidationErrors([])
        setFormState(emptyForm)
        setLoading(false)
        onClose()
    }

    return (
        <Dialog header={intl.formatMessage({
            id: updatingExistingUser
                ? "sftp.user.create.dialog.header.update"
                : "sftp.user.create.dialog.header.create"
        })}
                visible={visible}
                style={{width: '50vw'}}
                onHide={() => onHide()}
                footer={
                    <div>
                        <Button label={intl.formatMessage({id: "sftp.user.create.dialog.action.cancel"})}
                                icon="pi pi-times"
                                onClick={() => onHide()} className="p-button-text"/>
                        <Button label={
                            updatingExistingUser
                                ? intl.formatMessage({id: "sftp.user.create.dialog.action.update-user"})
                                : intl.formatMessage({id: "sftp.user.create.dialog.action.create-user"})}
                                icon="pi pi-check"
                                loading={loading}
                                onClick={() => onFormSubmit()}/>
                    </div>}>
            <div className="m-0">

                {!updatingExistingUser
                    ? <Message className="w-full justify-content-start mb-4" severity={"warn"}
                               text={intl.formatMessage({id: "sftp.user.create.dialog.warning"})}/>
                    : ""}

                {validationErrors.find(value => value.field === 'admin')
                    ? <Message className="w-full justify-content-start mb-4" severity={"error"}
                               text={intl.formatMessage({id: "error.not.admin.email"})}/>
                    : ""}

                <div className="field grid">
                    <label htmlFor="application" className="col-12 mb-2 md:col-2 md:mb-0">
                        {intl.formatMessage({id: "sftp.user.create.dialog.label.application"})}{updatingExistingUser ? '' : ' *'}
                    </label>
                    <div className="col-12 md:col-10">
                        {updatingExistingUser
                            ? formState.application ? <ApplicationBadge application={formState.application}/> : <span />
                            : <Dropdown
                                inputId="application"
                                options={[
                                    {label: "FMS", value: "FMS"},
                                    {label: "MRT", value: "MRT"},
                                    {label: "ORD", value: "ORD"},
                                    {label: "REI", value: "REI"},
                                ]}
                                onChange={e => {
                                    setFormState({
                                        ...formState,
                                        application: e.value
                                    })
                                }}
                                className={`w-full ${validationErrors.find(value => value.field === 'application') ? 'p-invalid' : ''}`}
                                value={formState.application}
                                showClear
                                autoFocus
                            />

                        }
                        <small id="sshKey-validation-error" className="p-error">
                            {validationErrors.find(value => value.field === 'application')?.message}
                        </small>
                    </div>
                </div>
                <div className="field grid">
                    <label htmlFor="username"
                           className="col-12 mb-2 md:col-2 md:mb-0">
                        {intl.formatMessage({id: "sftp.user.create.dialog.label.username"})}{updatingExistingUser ? '' : ' *'}
                    </label>
                    <div className="col-12 md:col-10">
                        {updatingExistingUser
                            ? <span>{formState.userName}</span>
                            : <InputText id="username"
                                         className={`w-full ${validationErrors.find(value => value.field === 'userName') ? 'p-invalid' : ''}`}
                                         value={formState.userName}
                                         onChange={e => setFormState({
                                             ...formState,
                                             userName: e.target.value
                                         })}/>
                        }
                        <small id="sshKey-validation-error" className="p-error">
                            {validationErrors.find(value => value.field === 'userName')?.message}
                        </small>
                    </div>
                </div>
                <div className="field grid">
                    <label htmlFor="email" className="col-12 mb-2 md:col-2 md:mb-0">
                        {intl.formatMessage({id: "sftp.user.create.dialog.label.email"})}&nbsp;*
                    </label>
                    <div className="col-12 md:col-10">
                        <InputText id="email"
                                   className={`w-full ${validationErrors.find(value => value.field === 'email') ? 'p-invalid' : ''}`}
                                   value={formState.email}
                                   onChange={e => setFormState({
                                       ...formState,
                                       email: e.target.value
                                   })}
                        />
                        <small id="sshKey-validation-error" className="p-error">
                            {validationErrors.find(value => value.field === 'email')?.message}
                        </small>
                    </div>
                </div>
                <div className="field grid">
                    <label className="col-12 mb-2 md:col-2 md:mb-0">
                        {intl.formatMessage({id: "sftp.user.create.dialog.label.logon-enabled"})}
                    </label>
                    <div className="col-12 md:col-10">
                        <SelectButton value={formState.logonEnabled}
                                      onChange={(e) => {
                                          setFormState({
                                              ...formState,
                                              logonEnabled: e.value as boolean
                                          })
                                      }}
                                      options={selectOptions}
                                      optionLabel="name"
                                      optionValue="value"/>
                    </div>
                </div>
                <div className="field grid">
                    <label className="col-12 mb-2 md:col-2 md:mb-0">
                        {intl.formatMessage({id: "sftp.user.create.dialog.label.admin"})}
                    </label>
                    <div className="col-12 md:col-10">
                        <SelectButton value={formState.admin}
                                      onChange={(e) => {
                                          setFormState({
                                              ...formState,
                                              admin: e.value as boolean
                                          })
                                      }}
                                      disabled={updatingExistingUser}
                                      options={selectOptions}
                                      optionLabel="name"
                                      optionValue="value"/>
                    </div>
                </div>

                {
                    formState.admin ? null :
                        <div className="field grid">
                            <label htmlFor="directory0"
                                   className="align-self-start col-12 mb-2 md:col-2 md:mb-0">
                                {intl.formatMessage({id: "sftp.user.create.dialog.label.directories"})}&nbsp;*
                            </label>
                            <div className="col-12 md:col-10">
                                {formState.directories
                                    ? formState.directories.map((directory, index) => {
                                        return (<div className="m-0" key={index}>
                                            <InputText
                                                id={"directory" + index}
                                                className={`w-full mb-2 ${validationErrors.find(value => value.field === 'directories') ? 'p-invalid' : ''}`}
                                                value={directory}
                                                onChange={e => handleChange(index, e.target.value)}/></div>)
                                    })
                                    : ""
                                }
                                <small id="sshKey-validation-error" className="p-error">
                                    {validationErrors.find(value => value.field === 'directories')?.message}
                                </small>
                            </div>
                        </div>
                }
            </div>
        </Dialog>
    )
}

export default CreateOrUpdateUserDialog
