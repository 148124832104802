import {useEffect, useState} from "react";

interface MenuItem {
    id: string,
    label: string,
    subMenuItems?: SubMenuItem[]

    callback?(event: any): void
}

interface SubMenuItem {
    id: string,
    label: string,

    callback(event: any): void
}

interface TopBarOptions {
    application: string,
    menuItems: Array<MenuItem>,

    onLanguageChange?(language: string): void
}

const TopBarWrapper = ({
                           application,
                           menuItems,
                           onLanguageChange
                       }: TopBarOptions) => {

	const [topbarCssLoaded, setTopbarCssLoaded] = useState<boolean>(false)
	const [topbarJsLoaded, setTopbarJsLoaded] = useState<boolean>(false)

	useEffect(() => {

		if (!topbarCssLoaded && !document.getElementById('dynamic-topbar-css')) {
			const link = document.createElement('link');
			link.href = window.REACT_APP_TOPBAR_CSS_URL;
			link.rel = 'stylesheet';
			link.id = 'dynamic-topbar-css'
			link.addEventListener("load", () => {
				setTopbarCssLoaded(true)
			})
			document.head.appendChild(link);
		}

		if (!topbarJsLoaded && !document.getElementById('dynamic-topbar-js')) {
			const script = document.createElement('script');
			script.src = window.REACT_APP_TOPBAR_JS_URL;
			script.id = 'dynamic-topbar-js'
			script.addEventListener("load", () => {
				setTopbarJsLoaded(true)
			})
			document.head.appendChild(script);
		}

    }, [topbarCssLoaded, topbarJsLoaded])

	if (!topbarJsLoaded || !topbarCssLoaded) {
		return (
			<div>
			</div>
		)
	}

    const itemAndCallbackFunction = new Map<string, Function>();

    menuItems.forEach(item => {
        if (item.callback) {
            itemAndCallbackFunction.set(item.id, item.callback);
        }

        if (item.subMenuItems) {
            item.subMenuItems.forEach(subMenu => {
                itemAndCallbackFunction.set(subMenu.id, subMenu.callback);
            })
        }
    });

    TopBar({
        footer: true,
        application: application,
        environment: window.REACT_APP_TOPBAR_ENVIRONMENT,
        menuItems: menuItems.map(item => {
            return {
                id: item.id,
                url: "#",
                label: item.label,
                subMenuItems: item.subMenuItems?.map(smItem => {
                    return {
                        ...smItem,
                        url: "#"
                    }
                })
            }
        }),
        menuItemClickedListeners: [{
            key: "menuItemClick",
			listener: function (_topbar, event, id) {
                event.preventDefault();
                itemAndCallbackFunction.get(id)?.(event);
            }
        }],
        languageListeners: [{
            key: "languageSwitch",
			listener: function (_topbar, language) {
                if (onLanguageChange) {
                    onLanguageChange(language)
                }
            }
        }]
    })

    return (
        <div>
        </div>
    );
}

export default TopBarWrapper;
